import React, { useState, useRef, useLayoutEffect } from 'react'
import { motion, useViewportScroll, useTransform } from "framer-motion";
import PageTitle from '../styled/PageTitle'

const ParallaxPageTitle = ({ 
  offset = 1000,
  children,
  className,
  center
}) => {
  const [elementTop, setElementTop] = useState(0);
  const titleRef = useRef(null);
  const { scrollY } = useViewportScroll();
  const y = useTransform(
    scrollY, 
    [0, elementTop + offset], 
    ['0px', offset/2+'px'],
    { clamp: false, }
  );

  useLayoutEffect(() => {
    const element = titleRef.current;
    setElementTop(element.offsetTop);
  }, [titleRef]);

  return (
    <motion.div ref={titleRef} style={{y, z: '-1px', zIndex: '-1'}}>
      <PageTitle center={center}>
        {children}
      </PageTitle>
    </motion.div>
  );
};

export default ParallaxPageTitle  