import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import {primaryTitleFont} from '../../styles/fonts'
import {quaternaryTitleFont} from '../../styles/fonts'

const PageTitle = styled.h1`
  font-family: ${primaryTitleFont};
  font-weight: 400;
  z-index: 0;

  ${({center}) => center && 'text-align: center;'}

  ${breakpoints.lg} {
    font-size: 6rem;
  }

  ${breakpoints.xl} {
    font-size: 8rem;
  }
`

export default PageTitle