import React, { useEffect } from "react";
import styled from 'styled-components'
import Container from '../components/styled/Container'
import ParallaxPageTitle from '../components/motion/ParallaxPageTitle'
import { breakpoints } from "../styles/breakpoints";
import { connect } from "react-redux";
import { codeFont } from "../styles/fonts";
import To from '../components/styled/To'

const StyledTo = styled(To)`
  font-family: ${codeFont};
  font-size: 1.5em;

  &:hover {
    text-decoration: underline;
  }
`

const StyledLi = styled.li`
  font-family: ${codeFont};
  padding-left: 40px;
  
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`

const contacts = [{
  label: 'LinkedIn',
  href: 'https://placekitten.com'
}, {
  label: 'Twitter',
  href: 'https://placekitten.com'
}, {
  label: 'Email',
  href: 'https://placekitten.com'
}, {
  label: 'Instagram',
  href: 'https://placekitten.com'
}]

const RegularPage = ({}) => {
  return (
    <Container>
      <ParallaxPageTitle>
        Hello There!
      </ParallaxPageTitle>
    
      <ul>
        {contacts.map((contact, i) => (
          <StyledLi key={'contact' + i}>
            <StyledTo href={contact.href}>
              {contact.label}
            </StyledTo>
          </StyledLi>
        ))}
      </ul>
    </Container>
  )
}

export default connect(({theme}) => ({reduxTheme: theme}))(RegularPage);
